﻿import { product_detail } from "./../objects/v1_3/product/product/product_detail";
import { product_detail_operational_information_duration } from "./../objects/v1_3/product/product/product_detail_operational_information_duration";
import { product_detail_operational_information_location } from "./../objects/v1_3/product/product/product_detail_operational_information_location";

import { pickup } from "./../objects/v1_3/product/pickup/pickup";

import { product_search } from "./../objects/v1_3/product/search/product_search";
import { product_search_product } from "./../objects/v1_3/product/search/product_search_product";

import { booking_group } from "../objects/v1_2/Booking/Booking_Group";
import { trip } from "../objects/v1_2/Booking/Trip";
import { currency } from "../objects/v1_2/Currency/currency";

import { Date_Service } from "../../system/services/date_service";
import { Rest_API } from "../../system/services/rest_api";

import { stringifyQuery } from 'vue-router';
import { age_cat } from "../objects/v1_3/web_engine/age_cat";
import { product_detail_seller_information_month } from "../objects/v1_3/product/product/product_detail_seller_information_month";
import { filtertag } from "../objects/v1_3/web_engine/filtertag";
import { filter } from "../objects/v1_3/web_engine/filter";
import { booking } from "../objects/v1_2/Booking_Put/booking";

const Date_Services: Date_Service = new Date_Service();

export class Product {

    Rest: Rest_API = new Rest_API();
    Date_Services: Date_Service = new Date_Service();

    public async Get_Details(tour_id: string, store: any): Promise<product_detail> {
        if (tour_id == "") {
            return new product_detail();
        }

        let Output: product_detail = this.Get_Details_Sync(tour_id, store);

        if (Output.tour_id == "") {
            var prod_detail = await this.Rest.Get_Data<product_detail>(import.meta.env.VITE_APP_URL + "/api/v1.3/" + import.meta.env.VITE_APP_ID + "/products/" + tour_id).then(response => { return response.json(); });
            store.commit("Product_Add", { Product_Details: prod_detail });
        }

        return this.Get_Details_Sync(tour_id, store);
    }
    public Get_Details_Sync(tour_id: string, store: any): product_detail {

        let Output: product_detail = new product_detail();

        store.getters.get_Products.forEach(function (product: product_detail) {
            if (product.tour_id == tour_id) {
                Output = product;
            }
        });

        return Output;
    }
    public product_pricing(age_category: string, Currency: currency, tour_searh_details: product_search_product): number {
        let Output: number = 0;

        tour_searh_details?.prices?.forEach(Price => {
            if (Price.iso_currency_code == Currency.currency_info.iso_currency_code) {
                Price.amounts.forEach(Amount => {
                    if (Amount.age_category == age_category) {
                        if (Output == 0 || Amount.amount < Output) {
                            Output = Amount.amount;
                        }
                    }
                });
            }
        });
        return Output;
    }
    public product_pricing_product(age_category: string, Currency: currency, tour_product_detail: product_detail): number {
        let Output: number = 0;

        tour_product_detail?.seller_information.prices?.forEach(Price => {
            if (Price.iso_currency_code == Currency.currency_info.iso_currency_code) {
                Price.amounts.forEach(Amount => {
                    if (Amount.age_category == age_category) {
                        if (Output == 0 || Amount.amount < Output) {
                            Output = Amount.amount;
                        }
                    }
                });
            }
        });
        return Output;
    }
    public get_minimum_price(age_category: string, Currency: currency, tour_product_search: product_search): number {
        let Output: number = 0;

        tour_product_search?.products?.forEach(Item => {
            let Price: number = this.product_pricing(age_category, Currency, Item);
            if (Output == 0 || Price < Output) {
                Output = Price;
            }
        });

        return Output;
    }
    public get_minimum_price_product(age_category: string, Currency: currency, tour_product_detail: product_detail): number {
        let Output: number = 0;

        let Price: number = this.product_pricing_product(age_category, Currency, tour_product_detail);
        if (Output == 0 || Price < Output) {
            Output = Price;
        }

        return Output;
    }
    public product_pricing_month(age_category: string, Currency: currency, year: number, month: number , tour_details: product_detail): number {
        let Output: number = 0;

        tour_details.seller_information?.months.forEach(Month => {
            if (Month.year == year && Month.month == month) {
                Month.currencies.forEach(Price => {
                    if (Price.iso_currency_code == Currency.currency_info.iso_currency_code) {
                        Price.amounts.forEach(Amount => {
                            if (Amount.age_category == age_category && Amount.room_type == null) {
                                Output = Amount.amount;
                            }
                        });
                    }
                });
            }
        });

        return Output;
    }
    public product_price_room(age_category: string | null, Currency: currency, year: number, month: number, product: product_detail, room: string | null): number {
        let Output: number = 0;

        product.seller_information?.months.forEach(Month => {
            if (Month.year == year && Month.month == month) {
                Month.currencies.forEach(Price => {
                    if (Price.iso_currency_code == Currency.currency_info.iso_currency_code) {
                        Price.amounts.forEach(Amount => {
                            if (Amount.age_category == age_category && Amount.room_type == room) {
                                Output = Amount.amount;
                            }
                        });
                    }
                });
            }
        });

        return Output;
    }
    public product_duration(tour_details: product_detail): string {
        return this.duration(tour_details?.operational_information?.duration);
    }
    public product_durationInDays(tour_details: product_detail): string {
        return this.durationInDays(tour_details?.operational_information?.duration);
    }
    public getRoundedDays(duration: product_detail_operational_information_duration): number {
        if(duration.hours != 0 || duration.minutes != 0) {
          return duration.days + 1;
        }
        else {
          return duration.days;
        }
      }
    public product_search_duration(tour_searh_details: product_search_product): string {
        return this.duration(tour_searh_details?.duration);
    }
    public product_overnight_duration(tour_details: product_detail): string {
        if (tour_details?.operational_information?.duration == null) {
            return "";
        }

        if (tour_details?.operational_information?.duration.days > 1) {
            if (tour_details?.operational_information?.duration.days == 2) {
                return "(1 <span class='text - sm'>night</span>)";
            }

            return "(" + (tour_details?.operational_information?.duration.days - 1) + " <span class='text - sm'>nights</span>)";
        }

        return "";
    }
    public product_overnight_rounded_duration(duration: product_detail_operational_information_duration): string {
        let rounded_days = this.getRoundedDays(duration);
        if (duration == null) {
            return "";
        }

        if (rounded_days > 1) {
            if (rounded_days == 2) {
                return "(1 <span class='text - sm'>night</span>)";
            }

            return "(" + (rounded_days - 1) + " <span class='text - sm'>nights</span>)";
        }

        return "";
    }
    public product_duration_information(tour_details: product_detail): string {
        if (tour_details?.operational_information?.duration == null) {
            return "";
        }

        if (tour_details?.operational_information?.duration.days > 0) {
            if (tour_details?.operational_information?.duration.days == 1) {
                return "1 <span class='text - sm'>day</span>";
            }

            return tour_details?.operational_information?.duration.days + " <span class='text - sm'>days</span>";
        }

        if (tour_details?.operational_information?.duration.hours > 0) {
            if (tour_details?.operational_information?.duration.hours == 1) {
                return "1 <span class='text - sm'>hour</span>";
            }

            return tour_details?.operational_information?.duration.hours + " <span class='text - sm'>hours</span>";
        }

        return "";
    }
    public product_duration_information_rounded(tour_details: product_detail): string {

        let rounded_days = this.getRoundedDays(tour_details?.operational_information?.duration);
        if (tour_details?.operational_information?.duration == null) {
            return "";
        }

        if (rounded_days > 0) {
            if (rounded_days == 1) {
                return "1 <span class='text - sm'>day</span>";
            }

            return rounded_days + " <span class='text - sm'>days</span>";
        }

        if (tour_details?.operational_information?.duration.hours > 0) {
            if (tour_details?.operational_information?.duration.hours == 1) {
                return "1 <span class='text - sm'>hour</span>";
            }

            return tour_details?.operational_information?.duration.hours + " <span class='text - sm'>hours</span>";
        }

        return "";
    }
    private duration(tour_duration: product_detail_operational_information_duration): string {
        if (tour_duration == null) {
            return "";
        }

        if (tour_duration.days > 0) {
            if (tour_duration.days == 1) {
                return "1 Day";
            }

            return tour_duration.days + " Days";
        }

        if (tour_duration.hours > 0) {
            if (tour_duration.hours == 1) {
                return "1 Hour";
            }

            return tour_duration.hours + " Hours";
        }

        return "";
    }

    private durationInDays(tour_duration: product_detail_operational_information_duration): string {
        let days = tour_duration.days ?? 0;

        if (tour_duration == null) {
            return "";
        }

        if(tour_duration.hours ?? 0 >= 1){
            days++;
        }

        if(days == 1){
            return days.toString() + " day";
        }
        else
        {
            return days.toString() + " days";
        }

    }
    public trip_starts(departure_date: string, tour_details: product_detail): string {
        if(tour_details?.operational_information?.location_start?.time == null || tour_details?.operational_information?.location_start?.time == ""){
            return this.Date_Services.full_date(departure_date);
        }
        else{
            return this.Date_Services.full_date(departure_date) + " at " + tour_details?.operational_information?.location_start?.time;
        }
    }
    public trip_starts_ends(departure_date: string, tour_details: product_detail): string {
        if ((tour_details.operational_information?.duration?.days ?? 0) == 0 || tour_details.operational_information?.duration?.days == 1) {
            return this.Date_Services.full_date(departure_date) + " at " + tour_details?.operational_information?.location_start?.time + " to " + this.end_time(departure_date, tour_details);
        }

        return "";
    }
    public trip_ends(departure_date: string, tour_details: product_detail): string {
        let rounded_days = this.getRoundedDays(tour_details.operational_information?.duration);    
        if(this.end_time(departure_date, tour_details) =="0:0"){ 
            return this.Date_Services.full_date(this.Date_Services.getReturnDate(departure_date, (rounded_days == 0) ? 1 : rounded_days));
        }
        else{
            return this.Date_Services.full_date(this.Date_Services.getReturnDate(departure_date, (rounded_days == 0) ? 1 : rounded_days)) + " at " + this.end_time(departure_date, tour_details)
        }

    }
    public end_time(departure_date: string, tour_details: product_detail): string {
        let Output: string = tour_details?.operational_information?.location_end?.time;

        if (Output == null) {
            Output = this.Date_Services.getReturnTime(departure_date + " " + tour_details?.operational_information?.location_start?.time, tour_details?.operational_information?.duration?.hours, tour_details?.operational_information?.duration?.minutes);
        }

        return Output;
    }
    public trip_checkintime(tour_details: product_detail): string {
        if (tour_details.operational_information.location_start.checkInTime != undefined) {
            return ", Check-in closes at " + tour_details.operational_information.location_start.checkInTime;
        }
        return "";
    }
    public trip_checkinurl(tour_details: product_detail): string {
        return tour_details.operational_information.location_start.url;
    }
    public trip_checkindescription(tour_details: product_detail): string {
        return tour_details.operational_information.location_start.description;
    }
    public product_start_location(tour_details: product_detail): string {
        let displaytext : string = tour_details.operational_information.location_start.country;
        if (tour_details.operational_information.location_start.city != undefined) {
            displaytext = tour_details.operational_information.location_start.city + ", " + displaytext;
        }
        return displaytext;
    }
    public product_end_location(tour_details: product_detail): string {
        let displaytext : string = tour_details.operational_information.location_end.country;
        if (tour_details.operational_information.location_end.city != undefined) {
            displaytext = tour_details.operational_information.location_end.city + ", " + displaytext;
        }
        return displaytext;
    }
    public get_image(tour_details: product_detail, width: number, height: number): string {
        return tour_details?.marketing_information?.media[0]?.assets[0]?.asset_url + "?anchor=center&mode=crop&width=1848";
    }
    public Get_Image_Map(tour_details: product_detail): string {
        return tour_details?.marketing_information?.media[1]?.assets[0]?.asset_url;
    }
    public async Get_Sales_Group(tour_id: string): Promise<product_search> {
        return this.Rest.Get_Data<product_search>(import.meta.env.VITE_APP_URL + "/api/v1.3/" + import.meta.env.VITE_APP_ID + "/products/search_list?filter=group_id:(" + tour_id + ")").then(response => { return response.json(); });
    }
    public async Get_Sales_AddOns(tour_id: string) {
        return this.Rest.Get_Data<product_search>(import.meta.env.VITE_APP_URL + "/api/v1.3/" + import.meta.env.VITE_APP_ID + "/products/search_list?filter=add_id:(" + tour_id + ")").then(response => { return response.json(); });
    }
    public Filtered_Products(Booking_Group: booking_group, Product_Search: product_search): product_search {
        let Product_Search_Output: product_search = new product_search();
        Product_Search.products.forEach((product: product_search_product) => {
            if (this.Contains_Product(product.tour_id, Booking_Group) == false) {
                Product_Search_Output.products.push(product);
            }
        });

        return Product_Search_Output;
    }
    public Filtered_out_Products(Booking_Group: booking_group, Product_Search: product_search): product_search {
        let Product_Search_Output: product_search = new product_search();
        Product_Search.products.forEach((product: product_search_product) => {
            if (this.Contains_Product(product.tour_id, Booking_Group) == true) {
                Product_Search_Output.products.push(product);
            }
        });

        return Product_Search_Output;
    }
    private Contains_Product(tour_id: string, Booking_Group: booking_group): boolean {
        let Product_Found: boolean = false;
        Booking_Group.trips.forEach((Trip: trip) => {
            if (Trip.tour_id == tour_id) {
                Product_Found = true;
            }
        });

        return Product_Found;
    }
    public async Get_Product_Search(): Promise<product_search> {
        return this.Rest.Get_Data<product_search>(import.meta.env.VITE_APP_URL + "/api/v1.3/" + import.meta.env.VITE_APP_ID + "/products/search_list?page_size=1000").then(response => { return response.json(); });
    }
    public async Get_Product_Search_Date_Range(filters: filtertag[], departure_from: string, departure_to: string): Promise<product_search> {
        if (departure_from == undefined || departure_to == undefined) {
            return new product_search();
        }

        let Tags: string = "";
        filters?.forEach(function (filter) {
            if (Tags == "") {
                Tags = "tag_id:(" + filter.tag_id + ")";
            } else {
                Tags += "*tag_id:(" + filter.tag_id + ")";
            }
        });

        if (Tags == "") {
            Tags = "filter=" + "departure_date_from:(" + departure_from + ")*departure_date_to:(" + departure_to + ")";
        } else {
            Tags = "filter=" + Tags + "*" + "departure_date_from:(" + departure_from + ")*departure_date_to:(" + departure_to + ")";
        }

        return this.Rest.Get_Data<product_search>(import.meta.env.VITE_APP_URL + "/api/v1.3/" + import.meta.env.VITE_APP_ID + "/products/search_list?page_size=1000&" + Tags).then(response => { return response.json(); });
    }
    public async Get_Product_Search_Month(filters: filtertag[], year: number, month: number): Promise<product_search> {
        if (year == undefined || month == undefined) {
            return new product_search();
        }

        let departure_from = Date_Services.output_date(year + "/" + month + "/01");

        if (month == 12) {
            month = 0;
            year += 1;
        }

        let departure_to = Date_Services.output_date(year + "/" + (month + 1) + "/01");

        return this.Get_Product_Search_Date_Range(filters, departure_from, departure_to);
    }
    public Get_Product_Search_Months(product_search: product_search): product_detail_seller_information_month[] {
        if (product_search?.products == undefined || product_search?.products?.length == 0) {
            return [];
        }

        return product_search.products[0].months;
    }
    public Get_Product_Search_Day(tour_id: string[], product_searches: product_search): product_search {
        let Product_Output: product_search = new product_search();
        Product_Output.products = [];

        product_searches.products.forEach(product => {
            tour_id.forEach(tour => {
                if (tour == product.tour_id) {
                    Product_Output.products.push(product);
                }
            });
        });

        Product_Output.products = Product_Output.products.sort((a, b) => a.name.localeCompare(b.name));

        return Product_Output;
    }
    public async Get_Product_Search_Filtered(filters: filtertag[], tags: filtertag[], product_search: string, departure_from: string, departure_to: string): Promise<product_search> {
        let Tags: string = "";
        if (Array.isArray(filters)) {
            filters.forEach(function (filter) {
                if (Tags == "") {
                    Tags = "tag_id:(" + filter.tag_id + ")";
                } else {
                    Tags += "*tag_id:(" + filter.tag_id + ")";
                }
            });
        }

        tags.forEach(function (tag) {
            if (Tags == "") {
                Tags = "tag_id:(" + tag.tag_id + ")";
            } else {
                Tags += "*tag_id:(" + tag.tag_id + ")";
            }
        });

        if (product_search != "") {
            if (Tags != "") {
                Tags += "*";
            }

            Tags += "name:(" + product_search + ")";
        }

        if ((departure_from ?? "") != "" && (departure_to ?? "") != "") {
            if (Tags == "") {
                Tags = "departure_date_from:(" + departure_from + ")*departure_date_to:(" + departure_to + ")";
            } else {
                Tags = Tags + "*" + "departure_date_from:(" + departure_from + ")*departure_date_to:(" + departure_to + ")";
            }
        }

        Tags = "?filter=" + Tags;

        return await this.Rest.Get_Data<product_search>(import.meta.env.VITE_APP_URL + "/api/v1.3/" + import.meta.env.VITE_APP_ID + "/products/search_list" + Tags + "&page_size=1000").then(response => { return response.json(); });
    }
    public async Get_Pickup_Points(pickup_point: string): Promise<pickup> {
        return await this.Rest.Get_Data<pickup>(import.meta.env.VITE_APP_URL + "/api/v1.3/" + import.meta.env.VITE_APP_ID + "/pickup/" + pickup_point).then(response => { return response.json(); });
    }
}